export const notifications = (state = {
    loader: {
        actions: []
    }
}, action) => {
    const { loader } = state;
    const { actions } = loader;

    switch (action.type) {
        case "CREATE_NOTIFICATIONS":
            return {
                ...state,
                loader: {
                    ...loader,
                    actions: [...actions, action.type]
                }
            };
        case "CREATE_NOTIFICATIONS_SUCCESS":
            return {
                ...state,
                loader: {
                    ...loader,
                    actions: actions.filter(a => a.type === action.type.substring(0, action.type.length - 8))
                }
            };
        default:
            return { ...state };
    }
}
