// Initialize Firebase
export const config = {
    apiKey: "AIzaSyBBNh_bV97rzfU-5vs6E7P_PCRkW6Hcsrs",
    authDomain: "nu-planning-v3-test.firebaseapp.com",
    databaseURL: "https://nu-planning-v3-test.firebaseio.com",
    projectId: "nu-planning-v3-test",
    storageBucket: "nu-planning-v3-test.appspot.com",
    messagingSenderId: "725894865267",
    appId: "1:725894865267:web:4afee49a6956b4c7d66506",
    measurementId: "G-L8PP3YJ93X",
}
