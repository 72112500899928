import * as firebase from "firebase"
import { history } from "../../../history"
import "firebase/auth"
import "firebase/database"

// import axios from "axios"
import { config } from "../../../authServices/firebase/firebaseConfig"
import { toast } from 'react-toastify';

// Init firebase if not already initialized
if (!firebase.apps.length) {
    firebase.initializeApp(config)
}

const firebaseAuth = firebase.auth()

export const submitLoginWithFireBase = (email, password, remember) => {
    return dispatch => {
        let userEmail = null,
            loggedIn = false
        firebaseAuth
            .signInWithEmailAndPassword(email, password)
            .then(result => {
                firebaseAuth.onAuthStateChanged(async (user) => {
                    if (user) {
                        let name = result.user.displayName;

                        const userSnapshot = await firebase.firestore().collection("users").doc(user.uid).get();
                        const userData = userSnapshot.data();

                        userEmail = user.email
                        loggedIn = true

                        firebaseAuth
                            .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                            .then(() => {
                                dispatch({
                                    type: "LOGIN_WITH_EMAIL",
                                    payload: {
                                        email: userEmail,
                                        name,
                                        isSignedIn: loggedIn,
                                        remember: true,
                                        loggedInWith: "firebase",
                                        userRole: userData.role
                                    }
                                })
                            })
                    }

                    history.push("/")
                })
            })
            .catch(error => {
                if (error.code === "auth/user-not-found" || error.code === "auth/wrong-password") {
                    toast.error(error.message);
                }
                console.log(error)
            })
    }
}

export const logoutWithFirebase = user => {
    return dispatch => {
        dispatch({ type: "LOGOUT_WITH_FIREBASE", payload: {} })
        history.push("/pages/login")
    }
}

export const changeRole = role => {
    return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}

export const setCurrentUser = () => {
    return async dispatch => {
        const user = firebase.auth().currentUser;

        if (user) {
            const userSnapshot = await firebase.firestore().collection("users").doc(user.uid).get();
            const userData = userSnapshot.data();

            let name = user.displayName;
            let userEmail = user.email

            firebaseAuth
                .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                .then(() => {
                    dispatch({
                        type: "SET_CURRENT_USER",
                        payload: {
                            email: userEmail,
                            name,
                            isSignedIn: true,
                            remember: true,
                            loggedInWith: "firebase",
                            userRole: userData.role
                        }
                    })
                })
            // history.push("/")

        } else {
            history.push("/pages/login")
        }
    }
}
