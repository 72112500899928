import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import availableBlocks from "./availableBlocks"
import productionLines from "./productionLines"
import planningBlocks from "./planningBlocks"
import notifications from "./notifications"
import users from "./users"

const rootReducer = combineReducers({
    customizer: customizer,
    auth: auth,
    navbar: navbar,
    availableBlocks: availableBlocks,
    productionLines: productionLines,
    planningBlocks: planningBlocks,
    notifications: notifications,
    users: users
})

export default rootReducer
