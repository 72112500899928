import React from "react"
import * as Icon from "react-feather"

const navigationConfig = [
    {
        id: "home",
        title: "Home",
        type: "item",
        icon: <Icon.Home size={20} />,
        permissions: ["admin", "planner", "schedule-board", "notSet"],
        navLink: "/"
    },
    {
        type: "groupHeader",
        groupTitle: "Planning",
        permissions: ["admin", "planner"],
    },
    {
        id: "availableBlock",
        title: "Availability",
        type: "collapse",
        icon: <Icon.CheckSquare size={20} /> ,
        permissions: ["admin", "planner"],
        children: [
            {
                id: "create",
                title: "Create",
                type: "item",
                icon: <Icon.Circle size={10} />,
                permissions: ["admin", "planner"],
                navLink: "/available-blocks/create"
            },
            {
                id: "pair",
                title: "Pair",
                type: "item",
                icon: <Icon.Circle size={10} />,
                permissions: ["admin", "planner"],
                navLink: "/available-blocks/pair"
            },
            {
                id: "overview",
                title: "Overview",
                type: "item",
                icon: <Icon.Circle size={10} />,
                permissions: ["admin", "planner"],
                navLink: "/available-blocks/overview"
            }
        ]
    },
    {
        id: "planning",
        title: "Planning",
        type: "item",
        icon: <Icon.Calendar size={20} />,
        permissions: ["admin", "planner"],
        navLink: "/planning/create"
    },
    {
        id: "productionLines",
        title: "Production Lines",
        type: "item",
        icon: <Icon.Package size={20} />,
        permissions: ["admin"],
        navLink: "/production-lines"
    },
    {
        id: "absence",
        title: "Absence",
        type: "item",
        icon: <Icon.Umbrella size={20} />,
        permissions: ["admin", "planner"],
        navLink: "/absence"
    },
    {
        type: "groupHeader",
        groupTitle: "HRM",
        permissions: ["admin", "planner"],
    },
    {
        id: "users",
        title: "Users",
        type: "item",
        icon: <Icon.User size={20} />,
        permissions: ["admin", "planner"],
        navLink: "/user/list"
    },
    {
        id: "hour-registration",
        title: "Hour Registration",
        type: "item",
        icon: <Icon.Clock size={20} />,
        permissions: ["admin"],
        navLink: "/hour-registration"
    },
    // {
    //     id: "users",
    //     title: "User",
    //     type: "collapse",
    //     icon: <Icon.User size={20} />,
    //     permissions: ["admin", "planner"],
    //     children: [
    //         {
    //             id: "list",
    //             title: "List",
    //             type: "item",
    //             icon: <Icon.Circle size={10} />,
    //             permissions: ["admin", "planner"],
    //             navLink: "/user/list"
    //         },
    //     ]
    // },
    {
        type: "groupHeader",
        groupTitle: "Etc.",
        permissions: ["admin", "planner"]
    },
    // {
    //     id: "chat",
    //     title: "Chat",
    //     type: "item",
    //     icon: <Icon.MessageSquare size={20} />,
    //     permissions: ["admin", "editor"],
    //     navLink: "/chat"
    // },
    {
        id: "posts",
        title: "Posts",
        type: "item",
        icon: <Icon.Send size={20} />,
        permissions: ["admin"],
        navLink: "/posts"
    },
    {
        id: "schedule-board",
        title: "Schedule board",
        type: "item",
        icon: <Icon.Calendar size={20} />,
        permissions: ["schedule-board"],
        navLink: "/schedule-board"
    },
    // {
    //     id: "settings",
    //     title: "Settings",
    //     type: "item",
    //     icon: <Icon.Settings size={20} />,
    //     permissions: ["admin", "editor"],
    //     navLink: "/settings"
    // }
]

export default navigationConfig
