import React from "react"
import { Navbar } from "reactstrap"
import classnames from "classnames"
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg"
import { connect } from 'react-redux'
import { logoutWithFirebase } from "../../../redux/actions/auth/loginActions"

const ThemeNavbar = props => {
    const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"]
    const navbarTypes = ["floating", "static", "sticky", "hidden"]

    const UserName = props => {
        let username = ""

        if (props.userdata !== undefined) {
            username = props.userdata.name
        } else if (props.user.login.values !== undefined) {
            username = props.user.login.values.email
            if (
                props.user.login.values.loggedInWith !== undefined &&
                props.user.login.values.loggedInWith === "jwt"
            ) {
                username = props.user.login.values.loggedInUser.name
            }
        } else {
            username = "John Doe"
        }

        return username
    }

    const UserRole = props => {
        let userRole = ""

        if (props.user.login.values !== undefined) {
            userRole = props.user.login.values.userRole
        }

        return userRole;
    }

    return (
        <React.Fragment>
            <div className="content-overlay" />
            <div className="header-navbar-shadow" />
            <Navbar
                className={classnames(
                    "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
                    {
                        "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
                        "navbar-dark": colorsArr.includes(props.navbarColor),
                        "bg-primary":
                            props.navbarColor === "primary" && props.navbarType !== "static",
                        "bg-danger":
                            props.navbarColor === "danger" && props.navbarType !== "static",
                        "bg-success":
                            props.navbarColor === "success" && props.navbarType !== "static",
                        "bg-info":
                            props.navbarColor === "info" && props.navbarType !== "static",
                        "bg-warning":
                            props.navbarColor === "warning" && props.navbarType !== "static",
                        "bg-dark":
                            props.navbarColor === "dark" && props.navbarType !== "static",
                        "d-none": props.navbarType === "hidden" && !props.horizontal,
                        "floating-nav":
                            (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
                        "navbar-static-top":
                            props.navbarType === "static" && !props.horizontal,
                        "fixed-top": props.navbarType === "sticky" || props.horizontal,
                        "scrolling": props.horizontal && props.scrolling

                    }
                )}
            >
                <div className="navbar-wrapper">
                    <div className="navbar-container content">
                        <div
                            className="navbar-collapse d-flex justify-content-between align-items-center"
                            id="navbar-mobile"
                        >
                            <div className="bookmark-wrapper">
                                <NavbarBookmarks
                                    sidebarVisibility={props.sidebarVisibility}
                                    handleAppOverlay={props.handleAppOverlay}
                                />
                            </div>
                            {props.horizontal ? (
                                <div className="logo d-flex align-items-center">
                                    <div className="brand-logo mr-50"></div>
                                    <h2 className="text-primary brand-text mb-0">Vuexy</h2>
                                </div>
                            ) : null}
                            <NavbarUser
                                handleAppOverlay={props.handleAppOverlay}
                                changeCurrentLang={props.changeCurrentLang}
                                userName={<UserName {...props} />}
                                userStatus={<UserRole {...props} />}
                                userImg={userImg}
                                loggedInWith={
                                    props.user !== undefined &&
                                        props.user.login.values !== undefined
                                        ? props.user.login.values.loggedInWith
                                        : null
                                }
                                logoutWithFirebase={props.logoutWithFirebase}
                            />
                        </div>
                    </div>
                </div>
            </Navbar>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.auth
    }
}

export default connect(mapStateToProps, {
    logoutWithFirebase
})(ThemeNavbar)
