export const planningBlocks = (state = {
    loader: {
        actions: [],
        userIds: {},
        planningBlockId: {},
        planningUser: {}
    }
}, action) => {
    const { loader } = state;
    const { actions } = loader;

    switch (action.type) {
        case "CREATE_PLANNING_BLOCK":
        case "UPDATE_PLANNING_BLOCK":
        case "DELETE_PLANNING_BLOCK":
        case "CREATE_MULTIPLE_PLANNING_BLOCKS":
        case "UPDATE_PLANNING_BLOCK_STATUS":
        case "UPDATE_PLANNING_USER":
            return {
                ...state,
                loader: {
                    ...loader,
                    actions: [...actions, action.type]
                }
            };


        case "CREATE_PLANNING_BLOCK_SUCCESS":
        case "UPDATE_PLANNING_BLOCK_SUCCESS":
        case "UPDATE_PLANNING_BLOCK_STATUS_SUCCESS":
        case "UPDATE_PLANNING_USER_SUCCESS":
            return {
                ...state,
                loader: {
                    ...loader,
                    actions: actions.filter(a => a.type === action.type.substring(0, action.type.length - 8))
                }
            };
        case "LINK_PLANNING_BLOCK":
        case "UNLINK_PLANNING_BLOCK":
        case "UNLINK_AND_LINK_PLANNING_BLOCK":
            return {
                ...state,
                loader: {
                    ...loader,
                    actions: [...actions, action.type],
                    userId: action.userId,
                    planningBlockId: action.planningBlockId,
                    planningUser: action.planningUser
                }
            };
        case "LINK_PLANNING_BLOCK_SUCCESS":
        case "UNLINK_PLANNING_BLOCK_SUCCESS":
        case "UNLINK_AND_LINK_PLANNING_BLOCK_SUCCESS":
            {
                return {
                    ...state,
                    loader: {
                        ...loader,
                        actions: [...actions, action.type],
                        userId: action.userId,
                        planningBlockId: action.planningBlockId,
                        planningUser: action.planningUser
                    }
                };
            }
        case "CREATE_UNPLANNED_PLANNING_BLOCK":
        case "CREATE_UNPLANNED_PLANNING_BLOCK_SUCCESS":
        case "CREATE_TIME_SPAN":
        case "UPDATE_TIME_SPAN":
        case "DELETE_TIME_SPAN":
        case "COPY_PLANNING_BLOCK":
        case "COPY_PLANNING_BLOCK_SUCCESS":
        case "PLANNING_BLOCK_REFRESH":
        case "CREATE_MULTIPLE_PLANNING_BLOCKS_SUCCESS":
        case "DELETE_PLANNING_BLOCK_SUCCESS":

            return {
                ...state,
                loader: {
                    ...loader,
                    actions: [...actions, action.type],
                }
            };

        case "CREATE_TIME_SPAN_SUCCESS":
        case "UPDATE_TIME_SPAN_SUCCESS":
        case "DELETE_TIME_SPAN_SUCCESS":
            return {
                ...state,
                loader: {
                    ...loader,
                    actions: [...actions, action.type],
                    planningBlockId: action.planningBlockId,
                }
            };
        default:
            return { ...state };
    }
}
