export const availableBlocks = (state = {
    loader: {
        actions: []
    }
}, action) => {
    const { loader } = state;
    const { actions } = loader;

    switch (action.type) {
        case "CREATE_AVAILABLE_BLOCK":
        case "UPDATE_AVAILABLE_BLOCK":
        case "DELETE_AVAILABLE_BLOCK":
        case "DELETE_AVAILABLE_BLOCKS_RANGE":
        case "CREATE_AVAILABLE_BLOCKS_RANGE":
        case "LINK_AVAILABLE_BLOCK":
        case "UNLINK_AVAILABLE_BLOCK":
        case "LINK_AVAILABLE_BLOCK_DAY":
        case "UNLINK_AVAILABLE_BLOCK_DAY":
            return {
                ...state,
                loader: {
                    ...loader,
                    actions: [...actions, action.type]
                }
            };
        case "CREATE_AVAILABLE_BLOCK_SUCCESS":
        case "UPDATE_AVAILABLE_BLOCK_SUCCESS":
        case "DELETE_AVAILABLE_BLOCK_SUCCESS":
        case "DELETE_AVAILABLE_BLOCKS_RANGE_SUCCESS":
        case "CREATE_AVAILABLE_BLOCKS_RANGE_SUCCESS":
        case "LINK_AVAILABLE_BLOCK_SUCCESS":
        case "UNLINK_AVAILABLE_BLOCK_SUCCESS":
        case "LINK_AVAILABLE_BLOCK_SUCCESS_DAY":
        case "UNLINK_AVAILABLE_BLOCK_SUCCESS_DAY":
            return {
                ...state,
                loader: {
                    ...loader,
                    actions: actions.filter(a => a.type === action.type.substring(0, action.type.length - 8))
                }
            };

        default:
            return { ...state };
    }
}
