import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { IntlProviderWrapper } from "./utility/context/Internationalization"
import { Layout } from "./utility/context/Layout"
import * as serviceWorker from "./serviceWorker"
import { store } from "./redux/storeConfig/store"
import Spinner from "./components/@vuexy/spinner/Fallback-spinner"
import "./index.scss"
import "./@fake-db"
import { ToastContainer } from 'react-toastify';

// Styles
import '../node_modules/react-toastify/dist/ReactToastify.css';
import "../node_modules/flatpickr/dist/themes/light.css";
import "../src/assets/scss/plugins/extensions/toastr.scss"
import "../src/assets/scss/plugins/forms/flatpickr/flatpickr.scss"

require('firebase/app');
require('@firebase/auth');
require('@firebase/storage');
require('@firebase/firestore');

const LazyApp = lazy(() => import("./App"))

// configureDatabase()
ReactDOM.render(
    <Provider store={store}>
        { }
        <ToastContainer />
        <Suspense fallback={<Spinner />}>
            <Layout>
                <IntlProviderWrapper>
                    <LazyApp />
                </IntlProviderWrapper>
            </Layout>
        </Suspense>
    </Provider>,
    document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
