export const users = (state = {
    loader: {
        actions: []
    }
}, action) => {
    const { loader } = state;
    const { actions } = loader;

    switch (action.type) {
        case "GET_FULL_USER":

            return {
                ...state,
                loader: {
                    ...loader,
                    actions: [...actions, action.type]
                }
            };
        case "GET_FULL_USER_SUCCESS":

            return {
                ...state,
                loader: {
                    ...loader,
                    actions: actions.filter(a => a.type === action.type.substring(0, action.type.length - 8))
                }
            };

        default:
            return { ...state };
    }
}
